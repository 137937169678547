import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService, UserRole } from '@ess-front/shared';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(): boolean {
    if (this.authService.isAuthenticated() && this.authService.getAuthData()?.role === UserRole.STAFF) {
      this.router.navigateByUrl('/');
      return false;
    }
    return true;
  }
}
